<template>
    <div class="p-2" v-if="shelter">
        <!-- header -->
        <div class="mb-1">
            <div @click="goBack()" class="h4 mb-0 text-white d-flex align-items-center" style="">
                <i class="bx bx-chevron-left" style="font-size: 20px;color: #FFAD32;"></i>
                Back
            </div>
        </div>

        <div class="p-1" style="border-radius: 10px;border:1px solid #FFAD32">
            <div class="d-flex">
                <img style="height:150px !important;width:150px !important;object-fit:cover" class="rounded-circle m-auto" 
                :src="shelter.shelter_picture" />
            </div>
        
            <div class="text-white text-center my-1 px-2">
                <div class="d-flex align-items-center justify-content-center" style="gap:10px">
                    <h2 class="text-white font-weight-bolder mb-0">{{ shelter.shelter_name }}</h2>
                    <router-link :to="`/chats/${shelter.user_id}`" v-if="user.user_id != shelter.user_id">
                        <div class="text-white d-flex align-items-center" style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;padding:.5em 1em">
                            <i class="bx bxs-message-dots" style="font-size: 18px;cursor: pointer;color: #FFAD32;"></i>
                        </div>
                    </router-link>
                    <router-link :to="`/shelter-register`" v-else>
                        <div class="text-white d-flex align-items-center" style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;padding:.5em 1em">
                            <i class="bx bxs-edit" style="font-size: 18px;cursor: pointer;color: #FFAD32;"></i>
                        </div>
                    </router-link>
                </div>
                <p class="mt-1">
                    {{ shelter.shelter_details }}
                </p>

                <h3 class="font-weight-bold text-white">{{shelter.shelter_location}}</h3>
                <h3 class="font-weight-bold text-white mb-0">{{ shelter.shelter_phone }}</h3>
            </div>
            
            <div class="d-flex">
                <div class="badge bg-danger m-auto" v-if="shelter.is_approved == 'n'">
                    Waiting for approval
                </div>
            </div>
        </div>
        <!-- end header -->

        <!-- volunteering -->

        <div class="d-flex justify-content-between align-items-center" style="gap:10px">
            <h3 class="text-white font-weight-bolder text-center mt-3 h3">Available Adoption</h3>
            <h3 @click="addNew()" class="text-white font-weight-bolder text-right mt-3 h6"
                v-if="user.user_id == shelter.user_id">Add Pet</h3>
        </div>

        <div class="h4 text-center text-white mt-1" v-if="adoption.length < 1">
            No Data Available
        </div>

        <div class="d-flex flex-column mt-1" style="gap:20px">
            <div class="p-1" style="border-radius: 10px;border:2px solid #FFAD32" v-for="item in adoption">
                
                <!-- USER HEADER INFO -->
                <div class="d-flex align-items-center justify-content-between py-1 mb-1 px-1" style="gap:10px 20px">
                    <div>
                        <img :src="item.sha_picture ? item.sha_picture : default_img"
                            class="rounded-circle" style="height:80px;width:80px" />
                    </div>
                    <div>
                        <h3 class="text-white h4 mb-0">{{item.sha_name}}</h3>
                        <h3 class="text-white h4 " style="margin: .25em 0 !important;">{{item.sha_species}}</h3>
                        <h3 class="text-white h4 mb-0">{{item.sha_age}} Months, {{item.sha_gender}}</h3>
                    </div> 
                    <i v-if="user.user_id == shelter.user_id" @click="edit(item)" class="bx bxs-edit text-primary ml-auto"
                    style="font-size: 30px;"></i>
                    <router-link v-else :to="`/shelter/adopt/detail/${item.sha_id}`" class="text-white h4 mb-0">
                        <i class="bx bx-detail text-primary ml-auto"
                                style="font-size: 30px;"></i>
                    </router-link>
                </div>
                <!-- END USER HEADER INFO -->

                <div class="d-flex align-items-center justify-content-center" style="gap:10px" v-if="user.user_id != shelter.user_id">
                    <!-- <div class="btn py-1" style="
                        border-radius: 15px;
                        border: 2px solid #FFAD32;
                        background: #005071;
                        color: white;
                    ">
                        <i class="bx bx-share-alt" style="font-size: 16px;cursor: pointer;color: #FFAD32;"></i>
                    </div> -->
                    <div class="btn px-1" style="
                        border-radius: 15px;
                        border: 2px solid #FFAD32;
                        background: #005071;
                        color: white;
                    " @click="reqAdoption(item)" v-if="!applied_adoption.includes(item.sha_id)">
                        Request Adoption
                    </div>
                    <div class="btn px-1" style="
                        border-radius: 15px;
                        border: 2px solid #FFAD32;
                        background: #005071;
                        color: white;
                    " v-else>
                        Already Requested
                    </div>
                    <div class="btn px-1" style="
                        border-radius: 15px;
                        border: 2px solid #FFAD32;
                        background: #005071;
                        color: white;
                    " @click="openModal(item)" v-if="!online_pets.includes(item.sha_id)">
                        Be Online Parent
                    </div>
                    <router-link class="btn px-1" :to="`/shelter/adopt/detail/${item.sha_id}`" style="
                        border-radius: 15px;
                        border: 2px solid #FFAD32;
                        background: #005071;
                        color: white;
                    " v-else>
                        You Are The Online Parent
                    </router-link>
                    <!-- <div class="btn py-1" style="
                        border-radius: 15px;
                        border: 2px solid #FFAD32;
                        background: #005071;
                        color: white;
                    ">
                        <i class="bx bxs-message-dots" style="font-size: 16px;cursor: pointer;color: #FFAD32;"></i>
                    </div> -->
                </div>
                <div v-else class="d-flex justify-content-between">
                    <router-link class="btn px-1" :to="`/shelter/adopt/detail/${item.sha_id}`" style="
                        border-radius: 15px;
                        border: 2px solid #FFAD32;
                        background: #005071;
                        color: white;
                    ">
                        See Detail
                    </router-link>
                    <button class="btn px-2" @click="deleteData(item.sha_id)" style="
                        border-radius: 15px;
                        border: 2px solid #FFAD32;
                        background: #EA5455;
                        color: white;
                        margin-left: .5em;
                    ">
                        Delete
                    </button>
                </div>

            </div>
        </div>
        <!-- end volunteering --> 

        <!-- modal -->
        <b-modal title="Online Adoption" centered content-class="blue-modal" header-class="" hide-footer hide-header v-model="isModalOpen">
            
            <div class="d-flex mt-1">
                <img :src="isShown.sha_picture ? isShown.sha_picture : default_img"
                    class="rounded-circle m-auto" style="height:100px;width:100px" />
            </div>

            <div class="text-center my-1">
                <h3 class="text-white h4 mb-0">{{isShown.sha_name}}</h3>
                <h3 class="text-white h4 " style="margin: .25em 0 !important;">{{isShown.sha_species}}</h3>
                <h3 class="text-white h4 mb-0">{{isShown.sha_age}} Months, {{isShown.sha_gender}}</h3>
                <p class="mt-1">
                    {{ isShown.sha_bio }}
                </p>
            </div>

            <div class="mt-3 h4 text-white font-weight-bolder">Would You Like To Be My Online Parent?</div>
            <h3 class="text-white h5 mb-0">How Much Would You Like To Provide Me Per Month?</h3>
            <input type="number" class="form-control mb-2 text-center" style="background-color: transparent;color: white;
                border:unset;border-bottom: 1px solid white !important;border-radius: unset;" v-model="donating_amount" />

            <div class="d-flex flex-wrap align-items-center justify-content-between mb-1" style="gap:10px">
                <div class="btn" style="
                    border-radius: 15px;
                    border: 2px solid #FFAD32;
                    background: #005071;
                    color: white;
                ">
                    <i class="bx bx-share-alt" style="font-size: 16px;cursor: pointer;color: #FFAD32;"></i>
                </div>
                <div class="btn px-3" style="
                    border-radius: 15px;
                    border: 2px solid #FFAD32;
                    background: #005071;
                    color: white;
                " @click="donateNow()">
                    Donate
                </div>
            </div>
            
        </b-modal>
        <!-- end modal -->

    </div>
</template>

<script>

import store from '@/store'
import moment from 'moment'
import Swal from 'sweetalert2'

import Cookies from 'universal-cookie'

let cookies = new Cookies()

export default {
    computed: {
        user() {
            return store.state.auth.USER
        },
        shelter() {
            return store.state.shelter.SHELTER
        },
        adoption() {
            return this.shelter?.adoption
        },
        online_pets(){
            return this.user?.online_pets?.map(item => (item.sha_id))
        },
        applied_adoption(){
            return this.user?.applied_adoption?.map(item => (item.sha_id))
        },
    },
    watch: {
        donating_amount(val){
            if(val > this.isShown.shd_fund_pledge - (this.isShown.shd_raised ? this.isShown.shd_raised : 0)){
                this.donating_amount = this.isShown.shd_fund_pledge - (this.isShown.shd_raised ? this.isShown.shd_raised : 0)
            }
        }
    },
    methods: {
        goBack(){
            this.$router.push('/shelter/profile/'+this.id)
        },
        deleteData(id){
            Swal.fire({
                title: 'Are you sure to delete this pet?',
                text: 'You can recover the data by contacting the admin',
                showCancelButton: true,
                confirmButtonText: 'Yes',
            }).then((result) => {
                if (result.isConfirmed) {
                    store.dispatch('shelter/DeleteAdoption', id).then(() => {
                        store.dispatch('shelter/GetShelter', this.id)
                    })
                }
            })
        },
        reqAdoption(item){
            this.$router.push('/shelter/adopt/form/'+ this.id + '/' + item.sha_id)
        },
        edit(item) {
            this.$router.push('/shelter/adopt/form-bus/' + this.id + '/' + item.sha_id)
        },
        addNew() {
            this.$router.push('/shelter/adopt/form-bus/' + this.id)
        },
        openModal(item) {
            this.isShown = item
            this.donating_amount = 0
            this.isModalOpen = true
        },
        donateNow() {
            store.dispatch('shelter/ApplyOnlineParent', {
                user_id: cookies.get('token'),
                sha_id: this.isShown.sha_id,
                shelter_id: this.id,
                nominal: this.donating_amount,
            }).then(() => {
                store.dispatch('auth/GetUserData')
                this.$router.push({ path: '/shelter/adopt/invoice/'+this.id, query: { 
                    nominal: this.donating_amount,
                    item: this.isShown
                }})
            })
        }
    },
    mounted() {
        store.dispatch('shelter/GetShelter', this.id)
        $(document).trigger("enhance");
    },
    data() {
        return {
            moment,
            donating_amount: 0,
            id: this.$route.params.id,
            isShown: {},
            isModalOpen: false,
            default_img: 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png'
        }
    }
}

</script>

<style>
.blue-modal {
    background-color: #1F9BCE;
    color: white;
    border-radius: 20px;
}

</style>